
import {ContentLoader} from 'vue-content-loader';
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ref } from 'vue';
import { verificaEmail, enviarNovaSenha} from "@/services/UsuarioService";
import { hideModal } from '@/core/helpers/dom';

export default{
    name:"modalRecuperacaoSenha",
    components: {
        ContentLoader
    },
    setup() {
        const email = ref('');  
        const emailError = ref('');  
        const emailValid = ref(false);  
        const loadingButton = ref(false);
        
        const validacaoEmail = () => {
            const regex = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;
            if(regex.test(email.value)){
                emailValid.value = true;
                emailError.value = '';
            } else {
                emailValid.value = false;  
                emailError.value = 'Insira um email válido';  
            }
        }

        const alertInfoDeletaSenha = async () => {
            loadingButton.value = true;
            const postData = {
                'email': email.value
            }
            
            try {
                const response = await verificaEmail(postData);

                if(response.success){
                    Swal.fire({
                        title: "Desejar continuar?",
                        text: "Ao enviar uma nova senha a sua senha anterior será deletada e prevalecerá a nova senha enviada por email!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        cancelButtonText: "Cancelar",
                        confirmButtonText: "Sim, alterar senha!"
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            loadingButton.value = true;
                            const postData = [
                                response.usuario.codUsuario,
                                response.usuario.email
                            ]
                            const novaSenhaResponse = await enviarNovaSenha(postData);
                            if(novaSenhaResponse.nova_senha){
                                Swal.fire({
                                    title: "Atualizada!",
                                    text: "Sua senha foi atualizada e enviada para o email cadastrado.",
                                    icon: "success"
                                });
                                closeModal();
                            }
                        }
                    });
                    // loadingButton.value = false;
                } else {
                    Swal.fire({
                        title: "Aviso!",
                        text: "O email informado não foi encontrado.",
                        icon: "warning"
                    });
                    // loadingButton.value = false;
                }
            } catch (error) {
                Swal.fire({
                    title: "Aviso!",
                    text: "O email informado não foi encontrado.",
                    icon: "warning"
                }); 
                // loadingButton.value = false;
            }
            finally{
                loadingButton.value = false;
            }
        }

        function closeModal(){
            email.value = '';
			const refModal = document.getElementById("modalRecuperacaoSenha");
			hideModal(refModal);
		}

        return {
            email,
            emailError,
            emailValid,
            validacaoEmail,
            alertInfoDeletaSenha,
            enviarNovaSenha,
            loadingButton,
            closeModal
        }
    }
}
