
import { defineComponent, ref, Ref } from "vue";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from "@/store/index";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { nivelRota, nivelRotaStellantis } from "@/core/config/NivelConfig";
import CryptoJS from "crypto-js";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import ModalRecuperacaoSenha from './ModalRecuperacaoSenha.vue';

interface Login {
  email: string;
  senha: string;
}

export default defineComponent({
  name: "login",
  components: {
    ModalRecuperacaoSenha
  },
  setup() {
    const isHovered = ref(false);
    const router = useRouter();
    const fullscreenLoading = ref(false);
    const remember = ref(true);
    const pushRouterDistribuidor = ['stellantis']
    const loginVar: Ref<Login> = ref({
      email: '',
      senha: ''
    })
    const errors = ref({
      email: '',
      senha: '',
    });

    const touched = ref({
      email: false,
      senha: false,
    });

    const handleBlur = (field) => {
      touched.value[field] = true;
      validateField(field);
    };

    const validateField = async (field: string) => {
      try {
        await loginSchema.validateAt(field, loginVar.value);
        errors.value[field] = '';
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          errors.value[field] = error.message;
        }
      }
    };

    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
    const linkIOS = store.getters.layoutConfig("distribuidor.link_loja_ios");
    const linkAndroid = store.getters.layoutConfig("distribuidor.link_loja_android");
    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const loginSchema = Yup.object().shape({
      email: Yup.string().email('Email deve ser valido').required('Informe email').label("Email"),
      senha: Yup.string().min(4, 'Deve ser maior que ${min}').required('Informe senha').label("Senha"),
    });

    const abrirModalSenha = () => {
      console.log('testando O click de recuperar senha');
    }

    //Form submit function
    const onSubmitLogin = async () => {
      try {
        await loginSchema.validate(loginVar.value, { abortEarly: false });
        // return;
        store.commit(Mutations.DELETE_ALL_NOTIFICATION);
        // console.log('values',values);
        const login = {
          email: CryptoJS.MD5(loginVar.value.email.toLowerCase()).toString(),
          senha: CryptoJS.MD5(loginVar.value.senha.toLowerCase()).toString(),
          rememberme: remember.value ? 1 : 0
        }

        store.dispatch(Actions.CLEAR);

        // Send login request
        store
          .dispatch(Actions.AUTENTICACAO, login)
          .then((response) => {
            fullscreenLoading.value = false;
            if (pushRouterDistribuidor.includes(distribuidor)) {
              router.push({ name: nivelRotaStellantis[response.usuario.codNivel] });
            } else {
              router.push({ name: nivelRota[response.usuario.codNivel] });
            }
          })
          .catch(() => {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            submitButton.value?.removeAttribute("disabled");
            Swal.fire({
              text: store.getters.getErrors,
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: "Tente novamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      } catch (error: any) {
        error.inner.forEach((e) => {
          errors.value[e.path] = e.message;
        });
      }
    };

    return {
      errors,
      touched,
      loginVar,
      onSubmitLogin,
      submitButton,
      distribuidor,
      linkAndroid,
      linkIOS,
      fullscreenLoading,
      remember,
      handleBlur,
      abrirModalSenha,
      isHovered
    };
  },
});
